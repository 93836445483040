<template>
    <EditBase :loading="loading" edicao :nome="usuario.nome" placeholder="Novo usuário">
        <p>
            Grupo: {{usuario.grupoUsuario.nome}}
            <br />
            Último login: {{new Date(usuario.ultimoLogin).toLocaleString()}}
        </p>
        <form @submit="formSalvar">
            <div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="i_nome">Nome *</label>
                        <input type="text" required="required" :disabled="saving" readonly="readonly" class="form-control" id="i_nome" placeholder="Exemplo: João Silva" v-model="usuario.nome">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="i_email">Email *</label>
                        <input type="email" maxlength="120" required="required" :disabled="saving" class="form-control" id="i_email" placeholder="Exemplo: joao@email.com" v-model="usuario.email">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="i_telefone">Telefone {{parseNotificacoes.includes('WhatsApp')  ? '*' : ''}}</label>
                        <phone-input v-if="parseNotificacoes.includes('WhatsApp')" required="required" :disabled="saving" class="form-control" id="i_telefone" placeholder="Exemplo: (00) 90000-0000" v-model="usuario.telefone" />
                        <phone-input v-else :disabled="saving" class="form-control" id="i_telefone" placeholder="Exemplo: (00) 90000-0000" v-model="usuario.telefone" />
                    </div>
                    <div class="form-group col-md-6">
                        <label for="i_notficacao">Notificações habilitadas</label>
                        <div class="notificacao">{{ parseNotificacoes }}</div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="i_senha">Senha</label>
                        <input
                            type="password"
                            :disabled="saving"
                            class="form-control"
                            id="i_senha"
                            placeholder="Senha inalterada"
                            v-model="usuario.senha"
                            @focus="infoSenha=true"
                            @blur="infoSenha=false"
                            pattern="(((?=.*\d)(?=.*[a-z])(?=.*[A-Z]))|((?=.*\d)(?=.*[a-z])(?=.*[^\s\w\d]))|((?=.*\d)(?=.*[^\s\d\w])(?=.*[A-Z]))|((?=.*[^\s\d\w])(?=.*[a-z])(?=.*[A-Z]))).{8,}"
                        >
                    </div>
                     <div class="form-group col-md-6">
                        <label for="i_senha2">Confirmação de senha {{usuario.senha ? '*' : ''}}</label>
                        <input type="password" :disabled="!usuario.senha || saving" class="form-control" id="i_senha2" placeholder="Confirme a senha" v-model="usuario.senha2">
                    </div>
                </div>
                <div v-show="infoSenha">
                    <div>A senha precisa conter no mínimo 8 caracteres e pelo menos um caractere de 3 das 4 categorias a seguir:</div>
                    <span :class="{ 'text-success': /[a-z]/.test(usuario.senha) }">letra minúscula</span>,
                    <span :class="{ 'text-success': /[A-Z]/.test(usuario.senha) }">letra maiúscula</span>,
                    <span :class="{ 'text-success': /[0-9]/.test(usuario.senha) }">número</span>,
                    <span :class="{ 'text-success': /[^\s\d\w]/.test(usuario.senha) }">caractere especial</span>
                </div>
                <div class="mb-2 text-danger d-flex align-items-center" v-if="senhasDiferem">
                    <x-circle-icon class="mr-1"/> As senhas diferem
                </div>

                <p class="small text-right">Campos marcados com * são obrigatórios.</p>
            </div>
            <div class="d-flex justify-content-end">
                <button class="btn btn-success align-self-center" type="submit" :disabled="loading || saving">Salvar</button>
            </div>
        </form>
    </EditBase>
</template>

<style scoped>
    .custom-select {
        width: auto;
    }
    .paginador {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
</style>

<script>
import EditBase from '@/templates/EditBase'
import PhoneInput from '@/components/PhoneInput'
import axios from 'axios'
import api from '@/api'
import { copyProps, TRY_TIMEOUT } from '@/helpers/common.js'

function options (timeout) {
    return {
        timeout: timeout || 2000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
    }
}

export default {
    name: 'Perfil',
    components: {
        EditBase,
        PhoneInput
    },
    data () {
        return {
            loading: true,
            saving: false,
            usuario: {
                id: 0,
                nome: '',
                email: '',
                senha: '',
                senha2: '',
                telefone: '',
                ativo: true,
                ultimoLogin: '',
                idGrupoUsuario: 0,
                grupoUsuario: {
                    id: 0,
                    nome: '',
                    descricao: '',
                },
            },
            infoSenha: false,
        };
    },
    computed: {
        parseNotificacoes () {
            let n = [];
            if (this.usuario.notificacoesEmail) n.push('Email');
            if (this.usuario.notificacoesTelefone) n.push('WhatsApp');
            return n.length ? n.join(', ') : 'Nenhuma';
        },
        senhasDiferem() {
            return this.usuario.senha && this.usuario.senha2 && this.usuario.senha !== this.usuario.senha2;
        }
    },
    methods: {
        getUsuario () {
            this.loading = true;
            axios.get(api.v1.perfil.get)
            .then(res => {
                if (res.data == null) { // usuario não existe
                    this.$snotify.error('O usuário solicitado não existe.', 'Erro');
                    this.$router.push('/logout');
                }
                res.data.senha = res.data.senha2 = '';
                this.usuario = res.data;
                this.loading = false;
            })
            .catch(reason => {
                if (![401, 403].includes(reason?.response?.status)) {
                    console.trace(reason);
                    this.$snotify.warning('Não foi possível buscar o usuário, tentando novamente...', 'Erro');
                    setTimeout(() => this.getUsuario(), TRY_TIMEOUT);
                }
                this.$snotify.warning('Não foi possível buscar o usuário.', 'Erro');
            })
        },

        salvar () {
            this.saving = true;
            this.$snotify.async('Aguarde...', 'Salvando', () => new Promise((resolve, reject) => {
                let payload =  this.usuario.senha ? copyProps(this.usuario)(['senha', 'senha2', 'email', 'telefone']) : copyProps(this.usuario)(['email', 'telefone']);
                axios.put(api.v1.perfil.update, payload)
                .then(res => {
                    res.data.senha = '';
                    res.data.senha2 = '';
                    this.usuario = res.data;
                    this.saving = false;
                    resolve({
                        title: 'Sucesso!',
                        body: 'Usuário salvo',
                        config: options()
                    })
                }).catch(e => {
                    this.saving = false;
                    reject({
                        title: 'Erro ao salvar',
                        body: e.response?.data.error ?? e.toString(),
                        config: options(5000)
                    })
                });
            }));
        },
        formSalvar (e) {
            e.preventDefault();
            this.salvar();
            return false;
        },
    },
    mounted () {
        this.getUsuario();
    },
}
</script>
<style scoped>
    .notificacao{
        margin-top: 7px;
        padding-left: 10px;
    }
</style>
