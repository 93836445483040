<template>
    <input type="tel" v-bind="$props" :value="phone" @input="mask" @change="transforma" pattern="\(\d{2}\) 9\d{4}-\d{4}" maxlength="15">
</template>
<script>
function formatPhone (phone) {
    const raw = phone.replace(/[^\d]/g, '');
    let number = '';
    if (raw.length > 2) number+=`(${raw.substr(2,2)}`;
    if (raw.length > 3) number+=`) ${raw.substr(4,5)}`;
    if (raw.length > 9) number+=`-${raw.substr(9)}`;
    return number;
}
function toE164BR (phone) {
    const raw = phone.replace(/[^\d]/g, '');
    return '+55'+raw;
}
function parsePhone (v) {
    const raw = (v || '').startsWith('+55') ? v : ('+55' + v.replace(/[^\d]/g, ''));
    return formatPhone(raw);
}
export default {
    data () {
        return {
            phone: '',
        };
    },
    watch: {
        value: 'attr',
    },
    created () {
        this.attr(this.value || '');
    },
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    methods: {
        attr (nv) {
            if (!nv.startsWith('+55')) {
                this.$emit('input', toE164BR(nv));
            } else {
                this.phone = parsePhone(nv);
            }
        },
        /**
         * @param {InputEvent} ev
         */
        mask (ev) {
            /** @type {string} */
            const raw = ev.target.value;
            let number = raw;
            let input = ev.data;
            let prev = raw.substr(0, raw.length - 1);
            if (input && (!input.match(/[0-9()\- ]/) || (input === '(' && number.length !== 1) || (input === ')' && number.length !== 4) || (input === '-' && number.length !== 11) || (input === ' ' && number.length !== 5))) {
                number = prev;
            } else if (input) {
                if (raw.length === 1 && input !== '(') number = '('+raw;
                else if (raw.length === 3) number = raw+') ';
                else if (raw.length === 4 && input !== ')') number = prev+') '+input;
                else if (raw.length === 5 && input !== ' ') number = prev+' '+input;
                else if (raw.length === 10) number = raw+'-';
                else if (raw.length === 11 && input !== '-') number = prev+'-'+input;
            }
            ev.target.value = number;
        },
        transforma (ev) {
            ev.target.value = parsePhone(ev.target.value);
            this.$emit('input', ev.target.value && toE164BR(ev.target.value));
        }
    }
}
</script>
