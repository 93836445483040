<template>
    <template-base>
        <div class="justify-content-between">
            <div v-if="loading">
                <tb-skeleton shape="radius" style="background-color: #dcdcdc; height: 3rem; width:100%" theme="opacity"></tb-skeleton>
                <tb-skeleton shape="radius" style="margin-right: 2%; margin-top: 2rem; display: inline-block; background-color: #dcdcdc; height: 3rem; width:49%" theme="opacity"></tb-skeleton>
                <tb-skeleton shape="radius" style="display: inline-block; background-color: #dcdcdc; height: 3rem; width:49%" theme="opacity"></tb-skeleton>
                <tb-skeleton shape="radius" style="margin-right: 2%; margin-top: 0.5rem; display: inline-block; background-color: #dcdcdc; height: 3rem; width:49%" theme="opacity"></tb-skeleton>
                <tb-skeleton shape="radius" style="display: inline-block; background-color: #dcdcdc; height: 3rem; width:49%" theme="opacity"></tb-skeleton>
            </div>
            <h1 v-else> {{ nome || (edicao ? '-' : placeholder) }} </h1>
        </div>
        <slot v-if="!loading" />
    </template-base>
</template>

<script>
import TemplateBase from '@/templates/Base'

export default {
    components: {
        TemplateBase,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        edicao: {
            type: Boolean,
            required: true,
        },
        nome: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            required: true,
        },
    },
}
</script>
